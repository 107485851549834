import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/max/Documents/node-projects/maxs-portfolio/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://mobile-sand-demo.web.app/"
      }}>{`Sand`}</a>{` uses the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/DeviceOrientationEvent"
      }}><inlineCode parentName="a">{`deviceorientation`}</inlineCode>{` event`}</a>{` to slide small squares across the screen drawn on an HTML `}<inlineCode parentName="p">{`<canvas>`}</inlineCode>{` based on the orientation of your mobile device. It simulates gravitational acceleration, friction and some randomness for a realistic sliding effect that creates very neat visual patterns. It is hosted with Firebase Hosting and its source code is available `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/sand"
      }}>{`on Github`}</a>{`. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      